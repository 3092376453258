import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Progres = () => {
    return {
        date: "April 19th, 2024",
		title: "PROGRES - The fragile infrastructure",
		description:
			"PROGRES is the official infrastructure system for the Algerian Ministry of Higher Education and Scientific Research, but what most people don't know that how unsecure and unstable it is. In this article, I will talk about how I was able to hack and access platform's data.",
		style: ``,
		keywords: [
			"PROGRES",
			"MESRS"
		],
		body: (
			<React.Fragment>
				<center><img src="/progres-logo.png" alt="PROGRES Logo"/></center>
				<h1>Introduction</h1>
				<p>If you work in higher education or are a college student in Algeria, you have
					probably heard of or are familiar with PROGRES, the platform that houses the whole
					higher education system, as well as its problems and glitches. However, did you
					realize that it's also brittle and open to several attacks that could jeopardize your
					personal information?
					<br/>
					I'll discuss how I found a flaw that allowed unauthorized access to all students' data
					in this post, as well as how I was able to execute actions that were deemed risky and
					harmful.
				</p>
				<h1>Why am I writing this?</h1>
				<p>This exploit is classified as high severity, since it exposes a huge amount of personal
					information of millions of citizens. As well, the ministry of superior education and 
					scientific researches has been notified about it but didn't take any action for months,
					which means that only way to bring this topic on the table is by opening it to the
					world, so they will be forced to take an action as soon as possible as it's getting out
					of hands.
					<br/>
					My main goal from doing all of this is protecting your personal data as an Algerian
					citizen from unethical hackers and criminals, as those information are enough to
					impersonate your identity, and by just impesronating it, crimes can be committed by
					your name and your life and career can be destroyed.
				</p>
				<br/>
				<h1>Section 1: Timeline</h1>
				<h2>23/01/2024: The idea of improving PROGRES WebEtu application</h2>
				<p>On January 23, I came up with the volunteer concept to rewrite PROGRES API
					Integration in order to enhance it and address the majority of common client-side
					faults; the project was intended to be open-source. Since it reveals the vulnerability,
					the repository is now private on GitHub.
				</p>
				<center><Zoom><img src="/progres-api-github.png" alt="PROGRES API GitHub Proof" width="100%" height="100%"/></Zoom></center>
				<h2>23/01/2024: Reverse-engineering the application</h2>
				<p>In order to redesign the app, we must first comprehend its operation. And in order to comprehend
					it, we must do something called reverse engineering, in which we obtain the application's
					source code, or at the very least, its instructions code, examine its behavior, and then duplicate
					it to obtain the same outcome.</p>
				<h2>31/01/2024: Proceeding into the rewrite</h2>
				<p>After obtaining and documenting all the required information, rewriting process has started,
					the chosen language was Java to provide compatibility with Android applications. But when I
					was doing some tests I figured out that in the same way I can obtain current account's
					information, I can also retrieve other's information without needing any special permissions.
					The only requirement was a valid PROGRES account (doesn't matter if it's either a student, teacher
					or any employee's account). And at this point, I came up with an idea of modifying WebEtu Android
					application, with this modification I broke the restriction of password authentication, so I can
					log-in into any student's account and perform any task such as viewing their academic information,
					student card and even requesting hebregement change and requesting reconsider exam notes. 
				</p>
				<h2>02/02/2024: First contact attempt (Failed)</h2>
				<p>The first attempt to contact the Ministry about this vulnerability didn't go well, as I tried
					to find any way to get in touch with them but their website was down so I didn't get any contact
					information. So the only way is reaching their official Facebook page and DMing them. There is no
					response from them till this day.
				</p>
				<h2>06/02/2024: Second contact attempt (Failed)</h2>
				<p>After failing to contact the ministry directly, I tried to let our university administration contact
					them. I got in touch with our computers engineer and gave her summary of what's going on with keeping
					the method of how it works more private and ambigous. She told me that she's going to contact the ministry
					as soon as possible but after that she forgot to.
				</p>
				<h2>15/02/2024: Third contact attempt (Succeed)</h2>
				<p>After doing more researches and some OSINT, I found a way to contact Ministry's Director of NETWORKS AND
					DIGITAL DEVELOPMENT, contacted him on Instagram and received a response after 56 minutes.
				</p>
				<h2>14/02/2024: First report provided</h2>
				<p>During the weekend between 16-17th February, I wrote a summary from 3 pages about how the vulnerability works
					and how did I discover it. I provided it to a doctor in our university who I got directed to by Ministry's
					employee, discussed about it. But, during discussion, we found that it's more than accessing accounts,
					it's about having superuser access to the API.
					<br/>
					I was requested to write a more detailed report, which I did next week.
				</p>
				<h2>21/02/2024: Second report provided</h2>
				<p>A report from 14 pages was provided containing more detailed information about the vulnerability,
					a step-by-step tutorial about how to reproduce it provided by some graphs to make understanding it
					more easier, and possible solutions.
				</p>
				<center><Zoom><img src="/progres-report-paper-proof.png" alt="Report Paper Proof" height="100%" width="100%"/></Zoom></center>
				<h2>17/03/2024: First attempt to ask about updates (Ghosted)</h2>
				<p>A month passed and the issue doesn't seem to be fixed, as it has high severity it should be fixed as
					soon as possible. I tried to contact the ministry's director again and there is no response till this day.
				</p>
				<h2>16/04/2024: Second attempt to ask about updates (Ghosted)</h2>
				<p>2 Months and there is nothing new, I tried to ask again. But this time, I contacted our university's doctor
					as he's the nearest one at the moment. No response is received.
				</p>
				<br/>
				<h1>Section 2: Severity</h1>
				<h2>Section 2.1: Information exposure (Possible data breaches)</h2>
				<p>As we mentioned before, we have full access into PROGRES backend, which means we can also grab all
					data and cause the largest data breach in country's history.
					<br/>
					If you want to check if you're vulnerable to breached I made a solution: <a href="/progres-data-checker">Click here</a>
				</p>
				<h2>Section 2.2: Performing unauthorized modifications</h2>
				<p>This vulnerabilty also allows anyone to performs actions that they're not supposed to. Don't be shocked if you
					find yourself got a License, Master or PhD degree without being eligible for it, or transferring your university
					residency without asking to.
				</p>
				<br/>
			</React.Fragment>
		)
    }
}

export default Progres;